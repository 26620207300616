import { cx } from '@linaria/core';
import { useParams, Redirect, useLocation } from '../dwouter';
import { StyledHeader as Header } from '../components/Header';
import { ContentPageLayout } from '../components/layouts/ContentPageLayout.jsx';
import { StyledFooter as Footer } from '../components/Footer';
import { StyledQuickNavigationList as QuickNavigationList } from '../components/a11y/QuickNavigationList.jsx';
import { PageWithData } from './PageWithData.jsx';
import { PageHeaderMetadata } from '../components/PageHeaderMetadata.jsx';
import { withTheme } from '../components/higherOrderComponents/withTheme.jsx';
import { VendorSettings } from '../components/VendorSettings.jsx';
import { isPathMatching } from '../components/commons/PathAnalyser';

export const VendorConsentPageNoQuery = ({
  privacySettings, className,
}) => (
  <>
    <PageHeaderMetadata content={privacySettings} />
    <QuickNavigationList content={privacySettings} />
    <Header content={privacySettings}></Header>
    <div className={className}>
      <ContentPageLayout
        DetailZoneFn={
          () => <VendorSettings privacySettings={privacySettings} />
        }
      />
    </div>
    <Footer content={privacySettings} />
  </>
);

const darkVendorConsentPageNoQueryStyles = cx(
  ContentPageLayout.darkStyles,
  VendorSettings.darkStyles,
);

export const VendorConsentPageNoQueryWithTheme = withTheme(VendorConsentPageNoQuery)(darkVendorConsentPageNoQueryStyles);

export const VendorConsentPage = () => {
  const { langCode } = useParams();
  const queryDef = {
    path: `${langCode}/vendor-consent`,
    depts: [langCode],
  };
  const [{ pathname, search }] = useLocation();

  return (
    <PageWithData queryDef={queryDef} langBaseContentFn={data => data.privacySettings} >
      {({ privacySettings }) => {
        if (!isPathMatching({ namedUrl: privacySettings.namedUrl, pathname })) {
          return <Redirect to={{ pathname: privacySettings.namedUrl, search }} />;
        }
        return (
          <VendorConsentPageNoQueryWithTheme privacySettings={privacySettings}/>
        );
      }
      }
    </PageWithData>
  );
};
