import { InView } from 'react-intersection-observer';
import { cx } from '@linaria/core';

import { StyledCurrentNews as CurrentNews } from '../ContentBlocks/CurrentNews';
import { StyledStoriesHub as StoriesHub } from '../ContentBlocks/Stories/StoriesHub';
import { StyledStoriesList as StoriesList } from '../ContentBlocks/Stories/StoriesList';
import { StyledDwRecommendsTopics as DwRecommendsTopics } from '../ContentBlocks/DWRecommends';
import { StyledPanoramaItems as PanoramaItems } from '../ContentBlocks/Panorama';
import { geoRegionHeadlineFn, geoRegionBottomLinkFn } from '../ContentBlocks/Stories/HighPriorityStories';
import { StyledOpinion as Opinion } from '../ContentBlocks/Opinion';
import { StyledWidgetContentBlock as WidgetContentBlock } from '../ContentBlocks/WidgetContentBlock.jsx';
import { TopicCluster } from '../ContentBlocks/TopicCluster';
import { StyledTopicClusterAutomatic as TopicClusterAutomatic } from '../ContentBlocks/TopicClusterAutomatic';
import { StyledTopicClusterAV as TopicClusterAV } from '../ContentBlocks/TopicClusterAV';
import { StyledCrossPromotion as CrossPromotion } from '../ContentBlocks/CrossPromotion';
import { StyledAVShowPlayer as AVShowPlayer } from '../ContentBlocks/AVShowPlayer';
import { StyledBreakingNews as BreakingNews } from '../ContentBlocks/BreakingNews';
import { StyledAVCarousel as AVCarousel } from '../ContentBlocks/AVCarousel';
import { StyledFeaturedAV as FeaturedAV } from '../ContentBlocks/FeaturedAV/FeaturedAV.jsx';
import { StyledManualContentList as ManualContentList } from '../ContentBlocks/ManualContentList';
import { MostViewed } from '../ContentBlocks/MostViewed';

import { TopStoryInterfaceAdapter } from './adapters/TopStoryInterfaceAdapter.jsx';
import { AVCarouselGlobalTopicVideoAdapter } from '../ContentBlocks/AVCarousel/AVCarouselGlobalTopicVideoAdapter.jsx';
import { AVCarouselProfileTopicVideoAdapter } from '../ContentBlocks/AVCarousel/AVCarouselProfileTopicVideoAdapter.jsx';
import { AVCarouselRegionVideoAdapter } from '../ContentBlocks/AVCarousel/AVCarouselRegionVideoAdapter.jsx';
import { AllProgramsTeaserInterfaceAdapter } from '../ContentBlocks/AllProgramsTeaser/AllProgramsTeaserInterfaceAdapter.jsx';
import { AVCarouselManualTopicVideoAdapter } from '../ContentBlocks/AVCarousel/AVCarouselManualTopicVideoAdapter.jsx';

import { ContentPageLayout } from '../layouts/ContentPageLayout.jsx';
import { darkCoCoComponentBackgroundSelectorStyles } from './CoCoComponentBackgroundSelector.jsx';
import { isValidList } from '../../utils/contentUtils';
import { noop, toKebabCase } from '../../utils/commons';
import { useTopOffsetHeaderThreshold } from '../hooks/useTopOffsetHeaderThreshold';
import { StyledCoCoAd as CoCoAd } from './CoCoAd.jsx';
import { isTopCoCoCo } from './coCoCoDetector';
import { isProgramInfoSpace } from '../zones/zoneUtils';
import { useHeaderColorToggle } from '../hooks/useHeaderColorToggle';
import { useFeatureFlag } from '../hooks/useFeatureFlag';

const templates = {
  CurrentNews: {
    cocoCmp: CurrentNews,
  },
  TopStory: {
    cocoCmp: TopStoryInterfaceAdapter,
  },
  Stories: {
    cocoCmp: StoriesList,
  },
  GeoLinkHubStories: {
    cocoCmp: StoriesHub,
    includesMultipleConfigs: true,
  },
  Hub: {
    cocoCmp: StoriesHub,
    includesMultipleConfigs: true,
  },
  DwRecommends: {
    cocoCmp: DwRecommendsTopics,
    extraProps: {
      headlineTranslation: 'content_block.dw_recommends.headline',
    },
  },
  Panorama: {
    cocoCmp: PanoramaItems,
    extraProps: {
      headlineTranslation: 'content_block.panorama.headline',
    },
  },
  Opinion: {
    cocoCmp: Opinion,
    extraProps: {
      headlineTranslation: 'content_block.opinion.headline',
    },
  },
  Av: {
    cocoCmp: AVShowPlayer,
  },
  AvCarousel: {
    cocoCmp: AVCarousel,
  },
};

const cocoCmpTypes = {
  NEWS: {
    type: 'NEWS',
    ...templates.CurrentNews,
  },
  NEWS_THEMATIC_FOCUS: {
    type: 'NEWS_THEMATIC_FOCUS',
    ...templates.CurrentNews,
  },
  ATS_NEWS: {
    type: 'ATS_NEWS',
    ...templates.CurrentNews,
  },
  NEWS_REGION: {
    type: 'NEWS_REGION',
    ...templates.CurrentNews,
  },
  NEWS_COUNTRY: {
    type: 'NEWS_COUNTRY',
    ...templates.CurrentNews,
  },
  TOP_STORY: {
    type: 'TOP_STORY',
    ...templates.TopStory,
  },
  TOP_STORY_THEMATIC_FOCUS: {
    type: 'TOP_STORY_THEMATIC_FOCUS',
    ...templates.TopStory,
  },
  TOP_STORY_COUNTRY: {
    type: 'TOP_STORY_COUNTRY',
    ...templates.TopStory,
  },
  TOP_STORY_REGION: {
    type: 'TOP_STORY_REGION',
    ...templates.TopStory,
  },
  ATS_TOP_STORY: {
    type: 'ATS_TOP_STORY',
    ...templates.TopStory,
  },
  STORIES_THEMATIC_FOCUS: {
    type: 'STORIES_THEMATIC_FOCUS',
    ...templates.Stories,
    extraProps: {
      headlineTranslation: 'content_block.stories_thematic_focus.headline',
    },
  },
  ATS_STORIES_LIST: {
    type: 'ATS_STORIES_LIST',
    ...templates.Stories,
    extraProps: {
      headlineTranslation: 'content_block.stories_thematic_focus.headline',
    },
  },
  RELATED_STORIES_THEMATIC_FOCUS: {
    type: 'RELATED_STORIES_THEMATIC_FOCUS',
    ...templates.Stories,
    extraProps: {
      headlineTranslation: 'content_block.related_stories_thematic_focus.headline',
    },
  },
  STORIES_LIST_REGION: {
    type: 'STORIES_LIST_REGION',
    ...templates.Stories,
    extraProps: {
      headlineTranslation: 'content_block.stories_region.headline',
    },
  },
  STORIES_LIST_COUNTRY: {
    type: 'STORIES_LIST_COUNTRY',
    ...templates.Stories,
    extraProps: {
      headlineTranslation: 'content_block.stories_country.headline',
    },
  },
  HIGH_PRIORITY_STORIES: {
    type: 'HIGH_PRIORITY_STORIES',
    ...templates.GeoLinkHubStories,
    extraProps: {
      headlineTranslation: 'content_block.high_priority_stories.headline',
      hubHeadlineFn: geoRegionHeadlineFn,
      hubBottomElementFn: geoRegionBottomLinkFn,
    },
  },
  HIGH_PRIORITY_STORIES_LIST: {
    type: 'HIGH_PRIORITY_STORIES_LIST',
    ...templates.DwRecommends,
    extraProps: {
      headlineTranslation: 'content_block.high_priority_stories.headline',
    },
  },
  HUB_THEMATIC_FOCUS: {
    type: 'HUB_THEMATIC_FOCUS',
    ...templates.Hub,
    extraProps: {
      headlineTranslation: 'content_block.hub_thematic_focus.headline',
    },
  },
  HUB_REGION: {
    type: 'HUB_REGION',
    ...templates.GeoLinkHubStories,
    extraProps: {
      headlineTranslation: 'content_block.hub_region.headline',
      hubHeadlineFn: geoRegionHeadlineFn,
      hubBottomElementFn: geoRegionBottomLinkFn,
    },
  },
  DW_RECOMMENDS: {
    type: 'DW_RECOMMENDS',
    ...templates.DwRecommends,
  },
  DW_RECOMMENDS_THEMATIC_FOCUS: {
    type: 'DW_RECOMMENDS_THEMATIC_FOCUS',
    ...templates.DwRecommends,
  },
  DW_RECOMMENDS_REGION: {
    type: 'DW_RECOMMENDS_REGION',
    ...templates.DwRecommends,
  },
  DW_RECOMMENDS_COUNTRY: {
    type: 'DW_RECOMMENDS_COUNTRY',
    ...templates.DwRecommends,
  },
  ATS_DW_RECOMMENDS: {
    type: 'ATS_DW_RECOMMENDS',
    ...templates.DwRecommends,
  },
  PANORAMA: {
    type: 'PANORAMA',
    ...templates.Panorama,
  },
  PANORAMA_THEMATIC_FOCUS: {
    type: 'PANORAMA_THEMATIC_FOCUS',
    ...templates.Panorama,
  },
  PANORAMA_REGION: {
    type: 'PANORAMA_REGION',
    ...templates.Panorama,
  },
  PANORAMA_COUNTRY: {
    type: 'PANORAMA_COUNTRY',
    ...templates.Panorama,
  },
  MOST_CLICKED: {
    type: 'MOST_CLICKED',
    cocoCmp: MostViewed,
    extraProps: {
      headlineTranslation: 'content_block.most_clicked.headline',
    },
  },
  OPINION: {
    type: 'OPINION',
    ...templates.Opinion,
  },
  OPINION_THEMATIC_FOCUS: {
    type: 'OPINION_THEMATIC_FOCUS',
    ...templates.Opinion,
  },
  OPINION_COUNTRY: {
    type: 'OPINION_COUNTRY',
    ...templates.Opinion,
  },
  OPINION_REGION: {
    type: 'OPINION_REGION',
    ...templates.Opinion,
  },
  ATS_OPINION: {
    type: 'ATS_OPINION',
    ...templates.Opinion,
  },
  WIDGET_WEBAPP: {
    type: 'WIDGET_WEBAPP',
    cocoCmp: WidgetContentBlock,
  },
  DOSSIER: {
    type: 'DOSSIER',
    cocoCmp: TopicCluster,
  },
  CROSS_PROMOTION: {
    type: 'CROSS_PROMOTION',
    cocoCmp: CrossPromotion,
  },
  ATS_TOPIC_CLUSTER: {
    type: 'ATS_TOPIC_CLUSTER',
    cocoCmp: TopicClusterAutomatic,
  },
  AV_NEWS: {
    type: 'AV_NEWS',
    ...templates.Av,
    extraProps: {
      isAVNews: true,
    },
  },
  AV_MAGAZINE: {
    type: 'AV_MAGAZINE',
    ...templates.Av,
  },
  NEWS_VIDEO: {
    type: 'NEWS_VIDEO',
    ...templates.AvCarousel,
    extraProps: {
      headlineTranslation: 'content_block.news_videos.headline',
    },
  },
  REPORTS_VIDEO: {
    type: 'REPORTS_VIDEO',
    ...templates.AvCarousel,
    extraProps: {
      headlineTranslation: 'content_block.reports_videos.headline',
    },
  },
  DW_RECOMMENDS_VIDEO: {
    type: 'DW_RECOMMENDS_VIDEO',
    ...templates.AvCarousel,
    extraProps: {
      headlineTranslation: 'content_block.dw_recommends.headline',
    },
  },
  FULL_SHOWS_VIDEO: {
    type: 'FULL_SHOWS_VIDEO',
    ...templates.AvCarousel,
    extraProps: {
      headlineTranslation: 'content_block.full_shows_videos.headline',
    },
  },
  PANORAMA_VIDEO: {
    type: 'PANORAMA_VIDEO',
    ...templates.AvCarousel,
    extraProps: {
      headlineTranslation: 'content_block.panorama.headline',
    },
  },
  TOPIC_CLUSTER_VIDEO: {
    type: 'TOPIC_CLUSTER_VIDEO',
    cocoCmp: TopicClusterAV,
  },
  FEATURED_VIDEO: {
    type: 'FEATURED_VIDEO',
    cocoCmp: FeaturedAV,
    extraProps: {
      headlineTranslation: 'content_block.featured_video.headline',
    },
  },
  FEATURED_AUDIO: {
    type: 'FEATURED_AUDIO',
    cocoCmp: FeaturedAV,
    extraProps: {
      headlineTranslation: 'content_block.featured_audio.headline',
    },
  },
  NEWS_AUDIO: {
    type: 'NEWS_AUDIO',
    ...templates.AvCarousel,
    extraProps: {
      headlineTranslation: 'content_block.news_audios.headline',
    },
  },
  REPORTS_AND_SHOWS_AUDIO: {
    type: 'REPORTS_AND_SHOWS_AUDIO',
    ...templates.AvCarousel,
    extraProps: {
      headlineTranslation: 'content_block.reports_and_shows_audios.headline',
    },
  },
  MANUAL_CONTENTS: {
    type: 'MANUAL_CONTENTS',
    cocoCmp: ManualContentList,
    extraProps: {
      headlineTranslation: 'content_block.stories_thematic_focus.headline',
    },
  },
  BREAKING_NEWS_WEBAPP: {
    type: 'BREAKING_NEWS_WEBAPP',
    cocoCmp: BreakingNews,
  },
  C_All_PROGRAMS_OVERVIEW: {
    type: 'C_All_PROGRAMS_OVERVIEW',
    cocoCmp: AllProgramsTeaserInterfaceAdapter,
  },
  GLOBAL_TOPIC_VIDEO: {
    type: 'GLOBAL_TOPIC_VIDEO',
    cocoCmp: AVCarouselGlobalTopicVideoAdapter,
  },
  PROFILE_TOPIC_VIDEO: {
    type: 'PROFILE_TOPIC_VIDEO',
    cocoCmp: AVCarouselProfileTopicVideoAdapter,
  },
  MANUAL_TOPIC_VIDEO: {
    type: 'MANUAL_TOPIC_VIDEO',
    cocoCmp: AVCarouselManualTopicVideoAdapter,
  },
  REGION_VIDEO: {
    type: 'REGION_VIDEO',
    cocoCmp: AVCarouselRegionVideoAdapter,
  },
};

const getCollectionName = cocoCmpConfig => (cocoCmpConfig.includesMultipleConfigs ? 'cocoContents' : 'contents');

export const getCoCoSectionId = compositionCmp => [toKebabCase(compositionCmp.type), compositionCmp.id].filter(v => !!v).join('-');

export const SingleCoCoComponentSelector = ({ compositionComp }) => {
  const cocoConf = cocoCmpTypes[compositionComp.type];
  const contents = compositionComp[getCollectionName(cocoConf)];

  const { cocoCmp: ContentBlockTag, extraProps = {} } = cocoConf;
  return (
    <ContentBlockTag
      contents={contents}
      configuration={compositionComp.configuration}
      className={toKebabCase(compositionComp.type)}
      pageSectionId={getCoCoSectionId(compositionComp)}
      {...extraProps}/>
  );
};

const hasCoCoContentsPredicate = compositionComp => {
  const cocoConf = cocoCmpTypes[compositionComp.type];
  const contents = compositionComp[getCollectionName(cocoConf)];
  return isValidList(contents);
};

// eslint-disable-next-line fp/no-mutating-methods
export const getCoCoCosWithContents = infoSpace => Object.values(cocoCmpTypes)
  .map(cocoCmpConfig => infoSpace[cocoCmpConfig.type.toLowerCase()])
  .reduce((acc, next) => [...acc, ...next], [])
  .filter(hasCoCoContentsPredicate)
  .sort((c1, c2) => c1.order - c2.order);

const darkCoCoComponentClassName = cx(
  ContentPageLayout.darkStyles,
  darkCoCoComponentBackgroundSelectorStyles,
);

export const CoCoComponentSelector = ({
  infoSpace, isFirst = false, bottomElementFn = noop,
}) => {
  const topOffsetHeaderThreshold = useTopOffsetHeaderThreshold();
  const compositionComponents = getCoCoCosWithContents(infoSpace);
  const bottomElement = bottomElementFn();
  const { onChangeInViewListenerForHeaderSwitch } = useHeaderColorToggle();

  const hasFeatureFlag = useFeatureFlag();

  return (
    compositionComponents.map((compositionComp, index) => {
      const isTopCoCo = isTopCoCoCo(compositionComp);

      const Container = (isFirst && index === 0) ? {
        Tag: InView,
        props: {
          rootMargin: topOffsetHeaderThreshold,
          onChange: onChangeInViewListenerForHeaderSwitch,
          as: isTopCoCo ? 'span' : 'div',
        },
      } : {
        Tag: isTopCoCo ? 'span' : 'div',
        props: {},
      };

      // TODO: Remove when most clicked is live
      if (compositionComp.type === 'MOST_CLICKED' && !hasFeatureFlag) {
        return null;
      }

      return (
        <Container.Tag key={compositionComp.id}
          className={cx(
            isTopCoCo
              ? 'basic-color'
              : 'auto-bg-color',
            isProgramInfoSpace(infoSpace) && darkCoCoComponentClassName,
          )}
          {...Container.props}
        >
          <SingleCoCoComponentSelector
            compositionComp={compositionComp}
          />
          { (index === compositionComponents.length - 1) && (
            <>
              {bottomElement}
              <CoCoAd infoSpace={infoSpace} compositionComponents={compositionComponents}/>
            </>
          )}
        </Container.Tag>
      );
    })
  );
};
