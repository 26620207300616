import gql from 'graphql-tag';
import { currentNewsFragment, currentNewsConfigFragment } from '../ContentBlocks/CurrentNews';
import { topStoryAdapterFragment } from './adapters/TopStoryInterfaceAdapter.jsx';
import { storiesHubFragment, storiesHubGeoRegionFragment } from '../ContentBlocks/Stories/StoriesHub';
import { storiesListFragment, storiesListConfigFragment } from '../ContentBlocks/Stories/StoriesList';
import { dwRecommendsTopicsFragment } from '../ContentBlocks/DWRecommends';
import { panoramaItemsFragment } from '../ContentBlocks/Panorama';
import { opinionFragment } from '../ContentBlocks/Opinion';
import { widgetContentBlockFragment } from '../ContentBlocks/WidgetContentBlock.jsx';
import { topicClusterFragment } from '../ContentBlocks/TopicCluster';
import {
  topicClusterAutomaticFragment,
  topicClusterAutomaticConfigFragment,
} from '../ContentBlocks/TopicClusterAutomatic';
import { topicClusterAVConfigFragment, topicClusterAVFragment } from '../ContentBlocks/TopicClusterAV';
import { crossPromotionFragment } from '../ContentBlocks/CrossPromotion';
import { avShowPlayerFragment } from '../ContentBlocks/AVShowPlayer';
import { breakingNewsFragment } from '../ContentBlocks/BreakingNews';
import { avCarouselFragment } from '../ContentBlocks/AVCarousel';
import { featuredAVFragment } from '../ContentBlocks/FeaturedAV/FeaturedAV.jsx';
import { manualContentListConfigFragment, manualContentListFragment } from '../ContentBlocks/ManualContentList';
import { allProgramsTeaserAdapterFragment } from '../ContentBlocks/AllProgramsTeaser/AllProgramsTeaserInterfaceAdapter.jsx';

import { cocoAdFragment } from './CoCoAd.jsx';
import { videoFragment } from '../Video';
import { audioFragment } from '../Audio';
import { makeMemoFragment } from '../../utils/graphql';

import {
  avCarouselGlobalTopicVideoAdapterConfigurationFragment,
  avCarouselGlobalTopicVideoAdapterFragment,
} from '../ContentBlocks/AVCarousel/AVCarouselGlobalTopicVideoAdapter.jsx';
import {
  avCarouselProfileTopicVideoAdapterConfigurationFragment,
  avCarouselProfileTopicVideoAdapterFragment,
} from '../ContentBlocks/AVCarousel/AVCarouselProfileTopicVideoAdapter.jsx';
import {
  avCarouselRegionVideoAdapterConfigurationFragment,
  avCarouselRegionVideoAdapterFragment,
} from '../ContentBlocks/AVCarousel/AVCarouselRegionVideoAdapter.jsx';
import {
  avCarouselManualTopicVideoAdapterFragment,
} from '../ContentBlocks/AVCarousel/AVCarouselManualTopicVideoAdapter.jsx';
import { mostViewedFragment } from '../ContentBlocks/MostViewed';

const templates = {
  CurrentNews: {
    mainFragment: currentNewsFragment,
    cocoConfigFragment: currentNewsConfigFragment,
  },
  TopStory: {
    mainFragment: topStoryAdapterFragment,
  },
  Stories: {
    mainFragment: storiesListFragment,
    cocoConfigFragment: storiesListConfigFragment,
  },
  GeoLinkHubStories: {
    mainFragment: storiesHubGeoRegionFragment,
    includesMultipleConfigs: true,
  },
  Hub: {
    mainFragment: storiesHubFragment,
    includesMultipleConfigs: true,
  },
  DwRecommends: {
    mainFragment: dwRecommendsTopicsFragment,
  },
  Panorama: {
    mainFragment: panoramaItemsFragment,
  },
  Opinion: {
    mainFragment: opinionFragment,
  },
  Av: {
    mainFragment: avShowPlayerFragment,
  },
  AvCarousel: {
    mainFragment: avCarouselFragment,
  },
};

const cocoCmpTypesToFragments = {
  NEWS: {
    type: 'NEWS',
    ...templates.CurrentNews,
  },
  NEWS_THEMATIC_FOCUS: {
    type: 'NEWS_THEMATIC_FOCUS',
    ...templates.CurrentNews,
  },
  ATS_NEWS: {
    type: 'ATS_NEWS',
    ...templates.CurrentNews,
  },
  NEWS_REGION: {
    type: 'NEWS_REGION',
    ...templates.CurrentNews,
  },
  NEWS_COUNTRY: {
    type: 'NEWS_COUNTRY',
    ...templates.CurrentNews,
  },
  TOP_STORY: {
    type: 'TOP_STORY',
    ...templates.TopStory,
  },
  TOP_STORY_THEMATIC_FOCUS: {
    type: 'TOP_STORY_THEMATIC_FOCUS',
    ...templates.TopStory,
  },
  TOP_STORY_COUNTRY: {
    type: 'TOP_STORY_COUNTRY',
    ...templates.TopStory,
  },
  TOP_STORY_REGION: {
    type: 'TOP_STORY_REGION',
    ...templates.TopStory,
  },
  ATS_TOP_STORY: {
    type: 'ATS_TOP_STORY',
    ...templates.TopStory,
  },
  STORIES_THEMATIC_FOCUS: {
    type: 'STORIES_THEMATIC_FOCUS',
    ...templates.Stories,
  },
  ATS_STORIES_LIST: {
    type: 'ATS_STORIES_LIST',
    ...templates.Stories,
  },
  RELATED_STORIES_THEMATIC_FOCUS: {
    type: 'RELATED_STORIES_THEMATIC_FOCUS',
    ...templates.Stories,
  },
  STORIES_LIST_REGION: {
    type: 'STORIES_LIST_REGION',
    ...templates.Stories,
  },
  STORIES_LIST_COUNTRY: {
    type: 'STORIES_LIST_COUNTRY',
    ...templates.Stories,
  },
  HIGH_PRIORITY_STORIES: {
    type: 'HIGH_PRIORITY_STORIES',
    ...templates.GeoLinkHubStories,
  },
  HIGH_PRIORITY_STORIES_LIST: {
    type: 'HIGH_PRIORITY_STORIES_LIST',
    ...templates.DwRecommends,
  },
  HUB_THEMATIC_FOCUS: {
    type: 'HUB_THEMATIC_FOCUS',
    ...templates.Hub,
  },
  HUB_REGION: {
    type: 'HUB_REGION',
    ...templates.GeoLinkHubStories,
  },
  DW_RECOMMENDS: {
    type: 'DW_RECOMMENDS',
    ...templates.DwRecommends,
  },
  DW_RECOMMENDS_THEMATIC_FOCUS: {
    type: 'DW_RECOMMENDS_THEMATIC_FOCUS',
    ...templates.DwRecommends,
  },
  DW_RECOMMENDS_REGION: {
    type: 'DW_RECOMMENDS_REGION',
    ...templates.DwRecommends,
  },
  DW_RECOMMENDS_COUNTRY: {
    type: 'DW_RECOMMENDS_COUNTRY',
    ...templates.DwRecommends,
  },
  ATS_DW_RECOMMENDS: {
    type: 'ATS_DW_RECOMMENDS',
    ...templates.DwRecommends,
  },
  PANORAMA: {
    type: 'PANORAMA',
    ...templates.Panorama,
  },
  PANORAMA_THEMATIC_FOCUS: {
    type: 'PANORAMA_THEMATIC_FOCUS',
    ...templates.Panorama,
  },
  PANORAMA_REGION: {
    type: 'PANORAMA_REGION',
    ...templates.Panorama,
  },
  PANORAMA_COUNTRY: {
    type: 'PANORAMA_COUNTRY',
    ...templates.Panorama,
  },
  MOST_CLICKED: {
    type: 'MOST_CLICKED',
    mainFragment: mostViewedFragment,
  },
  OPINION: {
    type: 'OPINION',
    ...templates.Opinion,
  },
  OPINION_THEMATIC_FOCUS: {
    type: 'OPINION_THEMATIC_FOCUS',
    ...templates.Opinion,
  },
  OPINION_COUNTRY: {
    type: 'OPINION_COUNTRY',
    ...templates.Opinion,
  },
  OPINION_REGION: {
    type: 'OPINION_REGION',
    ...templates.Opinion,
  },
  ATS_OPINION: {
    type: 'ATS_OPINION',
    ...templates.Opinion,
  },
  WIDGET_WEBAPP: {
    type: 'WIDGET_WEBAPP',
    mainFragment: widgetContentBlockFragment,
  },
  DOSSIER: {
    type: 'DOSSIER',
    mainFragment: topicClusterFragment,
  },
  CROSS_PROMOTION: {
    type: 'CROSS_PROMOTION',
    mainFragment: crossPromotionFragment,
  },
  ATS_TOPIC_CLUSTER: {
    type: 'ATS_TOPIC_CLUSTER',
    mainFragment: topicClusterAutomaticFragment,
    cocoConfigFragment: topicClusterAutomaticConfigFragment,
  },
  AV_NEWS: {
    type: 'AV_NEWS',
    ...templates.Av,
  },
  AV_MAGAZINE: {
    type: 'AV_MAGAZINE',
    ...templates.Av,
  },
  NEWS_VIDEO: {
    type: 'NEWS_VIDEO',
    ...templates.AvCarousel,
  },
  REPORTS_VIDEO: {
    type: 'REPORTS_VIDEO',
    ...templates.AvCarousel,
  },
  DW_RECOMMENDS_VIDEO: {
    type: 'DW_RECOMMENDS_VIDEO',
    ...templates.AvCarousel,
  },
  FULL_SHOWS_VIDEO: {
    type: 'FULL_SHOWS_VIDEO',
    ...templates.AvCarousel,
  },
  PANORAMA_VIDEO: {
    type: 'PANORAMA_VIDEO',
    ...templates.AvCarousel,
  },
  TOPIC_CLUSTER_VIDEO: {
    type: 'TOPIC_CLUSTER_VIDEO',
    mainFragment: topicClusterAVFragment,
    cocoConfigFragment: topicClusterAVConfigFragment,
  },
  FEATURED_VIDEO: {
    type: 'FEATURED_VIDEO',
    mainFragment: featuredAVFragment,
  },
  FEATURED_AUDIO: {
    type: 'FEATURED_AUDIO',
    mainFragment: featuredAVFragment,
  },
  NEWS_AUDIO: {
    type: 'NEWS_AUDIO',
    ...templates.AvCarousel,
  },
  REPORTS_AND_SHOWS_AUDIO: {
    type: 'REPORTS_AND_SHOWS_AUDIO',
    ...templates.AvCarousel,
  },
  MANUAL_CONTENTS: {
    type: 'MANUAL_CONTENTS',
    mainFragment: manualContentListFragment,
    cocoConfigFragment: manualContentListConfigFragment,
  },
  BREAKING_NEWS_WEBAPP: {
    type: 'BREAKING_NEWS_WEBAPP',
    mainFragment: breakingNewsFragment,
  },
  C_All_PROGRAMS_OVERVIEW: {
    type: 'C_All_PROGRAMS_OVERVIEW',
    mainFragment: allProgramsTeaserAdapterFragment,
  },
  GLOBAL_TOPIC_VIDEO: {
    type: 'GLOBAL_TOPIC_VIDEO',
    mainFragment: avCarouselGlobalTopicVideoAdapterFragment,
    cocoConfigFragment: avCarouselGlobalTopicVideoAdapterConfigurationFragment,
  },
  PROFILE_TOPIC_VIDEO: {
    type: 'PROFILE_TOPIC_VIDEO',
    mainFragment: avCarouselProfileTopicVideoAdapterFragment,
    cocoConfigFragment: avCarouselProfileTopicVideoAdapterConfigurationFragment,
  },
  MANUAL_TOPIC_VIDEO: {
    type: 'MANUAL_TOPIC_VIDEO',
    mainFragment: avCarouselManualTopicVideoAdapterFragment,
  },
  REGION_VIDEO: {
    type: 'REGION_VIDEO',
    mainFragment: avCarouselRegionVideoAdapterFragment,
    cocoConfigFragment: avCarouselRegionVideoAdapterConfigurationFragment,
  },
};

const getCollectionName = cocoCmpConfig => (cocoCmpConfig.includesMultipleConfigs ? 'cocoContents' : 'contents');

const getDataRequirementsForCoCoComponents = () => Object.values(cocoCmpTypesToFragments).map(cocoCmpConfig => {
  const collectionFieldName = getCollectionName(cocoCmpConfig);

  return `${cocoCmpConfig.type.toLowerCase()}: compositionComponents(type: ${cocoCmpConfig.type}) {
    id
    type
    order
    ${cocoCmpConfig.cocoConfigFragment ? `...${cocoCmpConfig.cocoConfigFragment.name}` : ''}
    ${collectionFieldName} {
      ...${cocoCmpConfig.mainFragment.name}
    }
  }`;
}).join('\n');

export const coCoComponentFragment = makeMemoFragment({
  name: 'CoCoComponentSelector',
  fragment() {
    return gql`fragment ${this.name} on InformationSpace {
        ${getDataRequirementsForCoCoComponents()}
        ...${cocoAdFragment.name}
      }
      ${cocoAdFragment.fragment()}
      ${videoFragment.fragment()}
      ${audioFragment.fragment()}

      ${templates.CurrentNews.mainFragment.fragment()}
      ${templates.CurrentNews.cocoConfigFragment.fragment()}
      ${templates.TopStory.mainFragment.fragment()}
      ${templates.Stories.mainFragment.fragment()}
      ${templates.Stories.cocoConfigFragment.fragment()}
      ${templates.GeoLinkHubStories.mainFragment.fragment()}
      ${templates.Hub.mainFragment.fragment()}
      ${templates.DwRecommends.mainFragment.fragment()}
      ${templates.Panorama.mainFragment.fragment()}
      ${templates.Opinion.mainFragment.fragment()}
      ${templates.Av.mainFragment.fragment()}
      ${templates.AvCarousel.mainFragment.fragment()}
      ${cocoCmpTypesToFragments.REPORTS_AND_SHOWS_AUDIO.mainFragment.fragment()}
      ${cocoCmpTypesToFragments.WIDGET_WEBAPP.mainFragment.fragment()}
      ${cocoCmpTypesToFragments.DOSSIER.mainFragment.fragment()}
      ${cocoCmpTypesToFragments.CROSS_PROMOTION.mainFragment.fragment()}
      ${cocoCmpTypesToFragments.ATS_TOPIC_CLUSTER.mainFragment.fragment()}
      ${cocoCmpTypesToFragments.ATS_TOPIC_CLUSTER.cocoConfigFragment.fragment()}
      ${cocoCmpTypesToFragments.FEATURED_AUDIO.mainFragment.fragment()}
      ${cocoCmpTypesToFragments.FEATURED_VIDEO.mainFragment.fragment()}
      ${cocoCmpTypesToFragments.TOPIC_CLUSTER_VIDEO.mainFragment.fragment()}
      ${cocoCmpTypesToFragments.TOPIC_CLUSTER_VIDEO.cocoConfigFragment.fragment()}
      ${cocoCmpTypesToFragments.MANUAL_CONTENTS.mainFragment.fragment()}
      ${cocoCmpTypesToFragments.MANUAL_CONTENTS.cocoConfigFragment.fragment()}
      ${cocoCmpTypesToFragments.BREAKING_NEWS_WEBAPP.mainFragment.fragment()}
      ${cocoCmpTypesToFragments.C_All_PROGRAMS_OVERVIEW.mainFragment.fragment()}
      ${cocoCmpTypesToFragments.GLOBAL_TOPIC_VIDEO.mainFragment.fragment()}
      ${cocoCmpTypesToFragments.GLOBAL_TOPIC_VIDEO.cocoConfigFragment.fragment()}
      ${cocoCmpTypesToFragments.PROFILE_TOPIC_VIDEO.mainFragment.fragment()}
      ${cocoCmpTypesToFragments.PROFILE_TOPIC_VIDEO.cocoConfigFragment.fragment()}
      ${cocoCmpTypesToFragments.MANUAL_TOPIC_VIDEO.mainFragment.fragment()}
      ${cocoCmpTypesToFragments.REGION_VIDEO.mainFragment.fragment()}
      ${cocoCmpTypesToFragments.REGION_VIDEO.cocoConfigFragment.fragment()}
      ${cocoCmpTypesToFragments.MOST_CLICKED.mainFragment.fragment()}
    `;
  },
});
