import {
  channelNameGroup, contentIdGroup, contentTypeGroup, langCodeGroup, pageNumberGroup, pathPartials, selectedTopicIdGroup, titleSlugGroup,
} from './components/commons/PathAnalyser';

export const startPageRedirectRegexp = `/${langCodeGroup}/?`;
export const peachExperimentRegexp = `/${langCodeGroup}/experiment/(?<peachPath>.*)`;
export const betaFeedbackRegexp = `/${langCodeGroup}/beta-feedback`;
export const notFound404Regexp = `/${langCodeGroup}/404`; // do we still use this?
export const privacySettingsRegexp = `/(${pathPartials.langCodeRegex}/)?(${titleSlugGroup}/)?privacy-settings-${langCodeGroup}`;
export const liveTvRegexp = `/${langCodeGroup}/live-tv/channel-${channelNameGroup}`;
export const azIndexRegexp = `/(${pathPartials.langCodeRegex}/)?(${titleSlugGroup}/)?index-${langCodeGroup}/?${selectedTopicIdGroup}?`;
export const headlinesOverviewRegexp = `/(${pathPartials.langCodeRegex}/)?(${titleSlugGroup}/)?headlines-${langCodeGroup}`;
export const programsOverviewRegexp = `/(${pathPartials.langCodeRegex}/)?(${titleSlugGroup}/)?programs-${langCodeGroup}`;
export const liveblogRegexp = `/(${langCodeGroup}/)?(${titleSlugGroup}/)?liveblog-post-${contentIdGroup}`;
export const contentRegexp = `/(${langCodeGroup}/)?(${titleSlugGroup}/)?${contentTypeGroup}-${contentIdGroup}`;
export const contentWithPageNumOldRegexp = `${contentRegexp}-${pageNumberGroup}`;
export const contentPaginatedRegexp = `/(${langCodeGroup}/)?(${titleSlugGroup}/)?${contentTypeGroup}-${contentIdGroup}/page-${pageNumberGroup}`;
export const searchPaginatedRegexp = `/${langCodeGroup}/search(/page-${pageNumberGroup})?`;
export const anyFromLanguageRegexp = `/${langCodeGroup}/(.*)`;
export const streamingVideoDetailRegexp = `/(${langCodeGroup}/)?(${titleSlugGroup}/)?svideo-${contentIdGroup}`;
