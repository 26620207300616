import { cx, css } from '@linaria/core';
import { MostViewedContentTeaser, mostViewedContentTeaserFragment } from './MostViewedContentTeaser.jsx';
import { textColors } from '../../Text/TextBase';
import { mediaMin } from '../../../utils/css';
import { DEFAULT_TEASER_SPACING } from '../../ContentTeaserSnippets/TeaserComposition';
import { globalCssVariables } from '../../Page/PageGlobalStyles';

export const mostViewedListFragment = mostViewedContentTeaserFragment;

export const MostViewedList = ({ contents = [], className, focusContainerClassName = '' }) => (
  <ol className={cx(className, mostViewedListClass)}>
    {contents.map(mostViewedContent => (
      <li key={mostViewedContent.id} className={cx('list-item', focusContainerClassName)}>
        <MostViewedContentTeaser
          content={mostViewedContent}
        />
      </li>
    ))}
  </ol>
);

export const mostViewedListClass = css`
  counter-reset: most-viewed-items-counter;
  padding: unset;

  .list-item {
    display: flex;
    padding-bottom: ${DEFAULT_TEASER_SPACING};
  }

  .list-item::before {
    content: counter(most-viewed-items-counter, var(${globalCssVariables.counterStyle}, decimal));
    counter-increment: most-viewed-items-counter;
    min-width: 30px;
    line-height: 1.1;
    font-weight: 600;
    font-size: 2.2rem;
    text-align: end;
    ${textColors.BLUE_GREY_04_AND_DW_LIGHT_BLUE.lightStyles}
  }

  ${mediaMin.md`
    .list-item::before {
      font-size: 2.4rem;
    }
  `}
`;
